<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" dayDate="true" />

    <div
      class="mt-10 projects"
      v-bind:key="project_info.id"
      v-for="project_info in projects"
    >
      <div class="pointer" @click="confirmUser(project_info)">
        <h2 class="mb-2">{{ project_info.username }}</h2>
        <p>
          <b>{{ project_info.address }}</b>
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "TodayProject",
  components: { CustomerInfo },

  data() {
    return {
      projects: [
        {
          id: 1,
          username: "Bill Morrison",
          address: "6221 N. Bales Ave, Gladstone, MO",
        },
        {
          id: 2,
          username: "Judith Stansbury",
          address: "1234 Main Street, Leavenworth, KS",
        },
      ],
    };
  },
  methods: {
    confirmUser(TodayProjectMain) {
      this.$router.push({
        name: "TodayProjectMain",
        params: {
          id: TodayProjectMain.id,
          name: TodayProjectMain.username,
          userAddress: TodayProjectMain.address,
        },
      });
    },
  },
};
</script>

<style scoped>
.projects {
  background: lightsalmon;
  cursor: pointer;
  width: 40%;
  padding: 20px;
}

.projects:nth-child(odd) {
  background: lightskyblue !important;
}
@media only screen and (max-width: 959px) {
  .projects {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .projects {
    width: 70%;
  }
}
@media only screen and (max-width: 767px) {
  .projects {
    width: 70%;
  }
}
@media only screen and (max-width: 587px) {
  .projects {
    width: 100%;
  }
}
</style>
